import Vue from 'vue'
import { IAppModule, ILink, IView } from '@/common/types'
import { Access } from '@/common/enums'
import { CustomerLinks } from './types'

export default class CustomerModule implements IAppModule {
  async init(): Promise<any> {
    Vue.component('dplus-tooth', (await import('./components/dplus-tooth.vue')).default)
    Vue.component('dplus-tooth-surface', (await import('./components/dplus-tooth-surface.vue')).default)
    Vue.component('dplus-customer-teeth', (await import('./components/dplus-customer-teeth.vue')).default)
    Vue.component('dplus-customer-curing', (await import('./components/dplus-customer-curing.vue')).default)
    Vue.component('dplus-customer-main', (await import('./components/dplus-customer-main.vue')).default)
    Vue.component('dplus-customer-tabs', (await import('./components/dplus-customer-tabs.vue')).default)
    Vue.component('dplus-customer-orto', (await import('./components/dplus-customer-orto.vue')).default)
    Vue.component('dplus-customer-orto-card', (await import('./components/dplus-customer-orto-card.vue')).default)
    Vue.component('dplus-customer-photos', (await import('./components/dplus-customer-photos.vue')).default)
    Vue.component('dplus-customer-photo', (await import('./components/dplus-customer-photo.vue')).default)
    Vue.component('dplus-customer-photo-preview', (await import('./components/dplus-customer-photo-preview.vue')).default)
    Vue.component('dplus-customer-visits', (await import('./components/dplus-customer-visits.vue')).default)
    Vue.component('dplus-customer-audit', (await import('./components/dplus-customer-audit.vue')).default)
    Vue.component('dplus-customer-lab', (await import('./components/dplus-customer-lab.vue')).default)
    Vue.component('dplus-timeline', (await import('./components/dplus-timeline.vue')).default)

    Vue.component('CustomerFindDialog', (await import('./views/CustomerFindDialog.vue')).default)
    Vue.component('CustomerCreateDialog', (await import('./views/CustomerCreateDialog.vue')).default)
    Vue.component('ToothTheraDialog', (await import('./views/ToothTheraDialog.vue')).default)
    Vue.component('ToothDamagesDialog', (await import('./views/ToothDamagesDialog.vue')).default)
    Vue.component('ToothNewManipulationsDialog', (await import('./views/ToothNewManipulationsDialog.vue')).default)

    Vue.component('ToothCureDialog', (await import('./views/ToothCureDialog.vue')).default)
    Vue.component('ToothManipulationsDialog', (await import('./views/ToothManipulationsDialog.vue')).default)
    Vue.component('VisitDialog', (await import('./views/VisitDialog.vue')).default)
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: CustomerLinks.CustomersList, path: '/customers', component: (await import('./views/CustomersList.vue')).default, access: Access.PatientViewer },
      { name: CustomerLinks.CustomerEdit, path: '/customers/:EntityId', component: (await import('./views/CustomerEdit.vue')).default, access: Access.PatientViewer, props: true }

    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [
    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
      { name: 'VisitReceipt', component: (await import('./reports/VisitReceipt.vue')).default },
      { name: 'CurePlanPrint', component: (await import('./reports/CurePlanPrint.vue')).default }
    ]
  }
}
